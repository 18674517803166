<template>
  <b-container class="galerias" v-loading="loading">
    <img src="https://arquivos.aquidauana.ms.gov.br/pub/banner-destaque.jpg" v-if="!active" />
    <h2>GALERIA DE FOTOS</h2>
    <div class="galerias__body">
      <div
        class="galerias__body__item"
        v-for="(foto, index) in fotosList.data"
        :key="index"
        @click.prevent="getPage(foto.gaf_cod, slugify(foto.gaf_titulo))"
      >
        <img :src="foto.imagem || ''" alt="" />
        <div class="gaf-text">
          <span class="gaf-text__title">{{ foto.gaf_titulo }}</span>
          <!-- <span class="gaf-text__time">{{
            foto.gaf_data | formatDate("L")
          }}</span> -->
        </div>
      </div>
    </div>
    <div v-if="Object.keys(fotosList).length > 0">
      <b-pagination
        align="center"
        v-model="fotosList.current_page"
        pills
        :total-rows="fotosList.total"
        @change="handleChangePage"
        :per-page="fotosList.per_page"
      ></b-pagination>
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'galerias',
  data() {
    return {
      loading: false,
      fotosList: [],
      windowWidth: 0,
      active: false
    };
  },
  methods: {
    async getGalerias(pg = 1) {
      this.loading = true;
      this.fotosList = [];
      window.scrollTo({
        top: 0,
      });
      const offset = await this.getOffset();
      const res = await this.$axios
        .get(`galerias?page=${pg}&offset=${offset}`)
        .catch((e) => {
          console.error('Galerias', e);
        });
      if (res.status === 200) {
        this.fotosList = res.data;
      }
      this.loading = false;
    },
    async getOffset() {
      const pageSize = this.windowWidth;
      let offset = 12;
      if (pageSize < 970) {
        offset = 10;
      } else if (pageSize >= 1290) {
        offset = 12;
      }
      return offset;
    },
    handleChangePage(pg) {
      this.getGalerias(pg);
    },
    slugify(text) {
      return text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-');
    },
    getPage(id, title) {
      this.$router.push(`${`/galeria/${id}/${title}`}`);
    },
  },
  mounted() {
    this.getGalerias();
  },
  created() {
    this.windowWidth = window.innerWidth;
  },
};
</script>

<style lang="scss">
.galerias {
  margin: 20px;
  font-family: Poppins;
  font-size: var(--font-size--default);

  &__body {
    position: relative;

    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 0 0 20px 0;
    margin: {
      top: 0;
      bottom: 0;
    }
    list-style: none;

    &__item {
      position: relative;
      max-width: 300px;
      margin-top: 20px;
      img {
        height: 200px;
        width: 300px;
        border-radius: 10px;
        object-fit: cover;
      }
      .gaf-text {
        background-color: var(--background-color-semitransparent);
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 55px;
        padding: 5px 10px;
        text-align: left;
        border-radius: 0 0 10px 10px;

        display: flex;
        flex-direction: column;
        justify-content: center;

        &__title,
        &__time {
          font-family: Poppins;
          font-weight: bold;
          color: var(--background-color-primary);
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
