<template>
  <div class="fotos-destaque" v-loading="loading">
    <h2>GALERIA DE FOTOS</h2>
    <div class="fotos-destaque__body">
      <ul class="fotos-destaque__body__list">
        <li
          class="fotos-destaque__body__item"
          v-if="!active"
        >
          <img
            src="https://arquivos.aquidauana.ms.gov.br/pub/aviso-destaque-galeria.jpg"
            alt=""
          />
        </li>
        <li
          v-for="(foto, index) in fotosList"
          :key="index"
          class="fotos-destaque__body__item"
          @click.prevent="getPage(foto.gaf_cod, slugify(foto.gaf_titulo))"
          :class="{ 'disabled': !active }"
        >
          <img :src="foto.imagem || ''" alt="" />
          <div class="gaf-text">
            <span class="gaf-text__title">{{ foto.gaf_titulo }}</span>
          </div>
        </li>
      </ul>
      <div class="see-all">
        <a href="/galerias" class="text-right" v-if="fotosList.length && active"
          ><span class="all-links">Ver todos</span></a
        >
      </div>
    </div>
  </div>
</template>

<script>
// import NewsCard from "@/components/News/NewsCards/NewsCard.vue";
export default {
  name: 'fotos-destaque',
  // components: { NewsCard },
  data() {
    return {
      fotosList: [],
      loading: false,
      windowWidth: 0,
      active: false
    };
  },
  methods: {
    async getFotos() {
      this.loading = true;
      const offset = await this.getOffset();
      const res = await this.$axios
        .get(`galerias/destaque?offset=${offset}`)
        .catch((e) => {
          console.error('Fotos Destaque', e);
        });
      if (res.status === 200) {
        this.fotosList = res.data;
        if (!this.active && this.fotosList.length > 0) {
          this.fotosList.pop(); // Remove o último item se active for false
        }
      }
      this.loading = false;
    },
    async getOffset() {
      const pageSize = this.windowWidth;
      let offset = 3;
      if (pageSize >= 1290) {
        offset = 4;
      }
      return offset;
    },
    slugify(text) {
      return text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-');
    },
    getPage(id, title) {
      if (this.active) {
        this.$router.push(`${`/galeria/${id}/${title}`}`);
      }
    },
  },
  mounted() {
    this.getFotos();
  },
  created() {
    this.windowWidth = window.innerWidth;
  },
};
</script>

<style lang="scss">
.imgvideo {
  border-radius: 10px;
  img {
    border-radius: 10px !important;
  }
}
.fotos-destaque {
  margin: 40px;
  padding-bottom: 20px;
  h2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: calc(var(--font-size--default) * 2);
    color: var(--background-color-secondary);
    text-align: left;
    padding-top: 0;
  }

  &__body {
    position: relative;
    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center;
      padding-left: 0;
      margin: {
        top: 0;
        bottom: 0;
      }
      list-style: none;
    }

    &__item {
      position: relative;
      max-width: 300px;
      margin-top: 20px;
      cursor: pointer;
      img {
        height: 200px;
        width: 300px;
        border-radius: 10px;
        object-fit: cover;
        object-position: top;
      }
      .gaf-text {
        background-color: var(--background-color-semitransparent);
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 55px;
        padding: 5px 10px;
        text-align: left;
        border-radius: 0 0 10px 10px;

        display: flex;
        flex-direction: column;
        justify-content: center;

        &__title,
        &__time {
          font-family: Poppins;
          font-weight: bold;
          color: var(--background-color-primary);
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }

  .see-all {
    display: flex;
    justify-content: flex-end;
    padding: 30px 0 0 0;
  }
  .text-right {
    width: 112px;
    height: 30px;
    background-color: var(--background-color-secondary);
    color: var(--background-color-primary);
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
    &:active,
    &:hover {
      background-color: var(--background-color-hover-buttons) !important;
      text-decoration: none;
    }
  }
}
</style>
